<template>
  <div id="app">
    <Form />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    Form: () => import('@/components/Form'),
  },
}
</script>

<style lang="scss" src="@/assets/app.scss"></style>
